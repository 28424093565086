/* eslint-disable @next/next/no-img-element */
import { useCallback, useRef } from "react";

import Image from "next/image";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper";

import styles from "styles/containers/brand-banners.module.scss";

import { ChevronLeft, ChevronRight } from "shared/components/Icons";

import { Brand } from "shared/core/Brand";

export default function BrandBanners({ images }) {
  const sliderRef = useRef();

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  return (
    <div className={styles.bannerContainer} id="brand-banners">
      <Swiper
        ref={sliderRef}
        autoHeight
        autoplay={{
          delay: 10000,
          disableOnInteraction: false,
        }}
        loop
        pagination={{
          el: "#swiper-pagination-banners-brand",
          clickable: true,
          renderBullet: (_, className) => {
            return `<span class="${className}"></span>`;
          },
        }}
        modules={[Autoplay, Pagination, Navigation]}
      >
        {images.map((image, _i) => {
          return (
            <SwiperSlide key={_i}>
              <div className={styles.picture}>
                <img
                  src={`${process.env.NEXT_PUBLIC_CDN_ASSETS}/${image}`}
                  alt={Brand.name}
                />
              </div>
            </SwiperSlide>
          );
        })}
        <div
          id="swiper-pagination-banners-brand"
          className={styles.swiperPagination}
        />
      </Swiper>
      <div onClick={handlePrev} className={styles.prev}>
        <ChevronLeft color="#8c8c8c" />
      </div>
      <div onClick={handleNext} className={styles.next}>
        <ChevronRight color="#8c8c8c" />
      </div>
    </div>
  );
}
